import { capitalize } from '@material-ui/core';
import React, { ReactElement } from 'react';

type AdCounterProps = {
  popsCount: number,
  stateCounts: Record<string, number>
};

const AdCounter = ({ popsCount, stateCounts }: AdCounterProps): ReactElement => (
  <>
    {' Pops: '}
    {popsCount}
    {' '}
    {Object.keys(stateCounts).map((key, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={idx}>
        {capitalize(key)}
        :
        {' '}
        {stateCounts[key]}
        {' '}
      </span>
    ))}
  </>
);

export default AdCounter;
